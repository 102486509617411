import React, { useState, useEffect } from 'react';
// import * as firebase from 'firebase/app';
// import firebase from 'firebase/app';
// import 'firebase/firestore';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const App = () => {
  const [description, setDescription] = useState('');
  const [giftIdeas, setGiftIdeas] = useState([]);
  const [databaseGiftIdeas, setDatabaseGiftIdeas] = useState([]);

    const firebaseConfig = {
  apiKey: "AIzaSyBFJlA_jGqKrwxDMH29Nv3WkAEIm5sriZM",
  authDomain: "kadoidee-950e4.firebaseapp.com",
  projectId: "kadoidee-950e4",
  storageBucket: "kadoidee-950e4.appspot.com",
  messagingSenderId: "511466832682",
  appId: "1:511466832682:web:e0fa01f2fcbbd1025d9453"
  };

  // const app = initializeApp(firebaseConfig);
  firebase.initializeApp(firebaseConfig);

    // Retrieve the gift ideas from the Firebase database
  useEffect(() => {
    firebase.firestore()
      .collection('gifts')
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data().giftIdeas);
        setDatabaseGiftIdeas(data);
      });
  }, []);

  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send the description to the OpenAI ChatGPT API
    fetch('https://api.openai.com/v1/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 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',
      },
      body: JSON.stringify({
        'model': 'text-davinci-003',
        'prompt': `return multiple ideas in list form for relevant presents for this person, a ${description}`,
        'max_tokens': 50,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setGiftIdeas(data.choices[0].text.split('\n'));
                const giftData = {
          description: description,
          giftIdeas: giftIdeas,
        };
        // Add the gift data to the Firebase database
        firebase
.firestore()
.collection('gifts')
.add(giftData);
      });
  };

  return (
   <div>
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Description:
          <input type="text" value={description} onChange={handleChange} />
        </label>
        <input type="submit" value="Submit" />
      </form>
      {giftIdeas.map((idea) => (
        <div key={idea}>{idea}</div>
      ))}
    </div>
    <div>
      <h1>Database gift ideas:</h1>
      {databaseGiftIdeas.map((ideas) => (
        <div key={ideas}>
          {ideas.map((idea) => (
            <div key={idea}>{idea}</div>
          ))}
        </div>
      ))}
    </div>
  </div>
);
};

export default App;